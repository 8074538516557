
import { SETTING_ACTION } from './../../store/actions/settings.action';
import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { DeviceList } from 'app/shared/model/device.list.model';
import { Subscription, Subject, takeUntil } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CommonService, WebsocketService } from 'app/shared/services/core';
import { CustomerListService, DeviceListService } from 'app/shared/services/comp';
import { GlobalConstants } from 'app/configs/constants';
import { AlertService } from 'app/shared/services/misc';
import { DashboardConfService } from 'app/modules/dashboard/services/dashboard-conf.service';
import { Store } from '@ngrx/store';
import { CosgridAppStore } from 'app/store/app.store';
import { Network } from 'app/shared/model/network';
import { SuperAdminListService } from 'app/store/services/super-admin-list.service';
import { ITile } from 'app/modules/dashboard/components/tile-group/tile-group.component';
import { IavailableDashboard } from 'app/shared/model/availableDashboard.model';
import { AVAILABLE_DASHBOARD_ACTION } from 'app/store/actions/availableDashboard.actions';
import { JoyrideService } from 'ngx-joyride';
import { IStepOption, TourService } from 'ngx-ui-tour-md-menu';
@Component({
    selector: 'cosgrid-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>()
    interfaces;
    selectedinterface;
    loading: boolean = false
    interval;
    errCount = 0;
    mapLoading: boolean = true
    private readonly tourService = inject(TourService);
    tenantinfo = {
        devices: ' - ',
        links: ' - ',
        online_devices: ' - ',
        active_links: ' - ',
        inactive_links: ' - ',
        total_networks: ' - ',
        deployed_devices: ' - ',
        offline_devices: '-',
        users: '-',
        online_users: '-',
    };
    deviceAvailable: boolean = true;
    showGraph = false;
    currentTenantId: string = this.customerListService.getTenantId() || '';

    currentTenantName: string = this.customerListService.getTenantName();

    selectedNetwork: Network = {
        networkName: '',
    };

    ztnaTourStep: IStepOption[] = [
        {
            anchorId: 'dashboard',
            title: 'Dashboard',
            placement: {
                xPosition: 'after', // 'before' | 'after'
                yPosition: 'below', // 'above' | 'below'
            },
            content: 'Provides visibility and control over access and user & device activities along with organisation level information overview and its corresponding status'
        },
        {
            anchorId: 'microzaccess',
            title: 'MircoZAccess Endpoint profile ',
            content: 'Creation of endpoint profiles for respective devices with specific accessibility for users or particular devices based on the security policies',
            enableBackdrop: true,
        },
        {
            anchorId: 'networks',
            title: 'Device Groups',
            content: 'Allow administrators to categorize devices based on various criteria, such as device type, operating system, or security posture. Enables granular access control and policy enforcement, enhancing security and compliance.'
        },
        {
            anchorId: 'teams',
            title: 'Teams',
            content: 'Collection of users and/or groups with shared access policies.Used to streamline access management and policy enforcement.'
        },
        {
            anchorId: 'users',
            title: 'Users',
            content: 'Represent human users, service accounts, or other entities that require access to resources.Each user has a unique identity and set of permissions.'
        },
        {
            anchorId: 'groups',
            title: 'Groups',
            content: 'Collection of users that share common access requirements. Simplify access management by defining policies for groups rather than individual users like departments, or roles within an organization.'
        },
        {
            anchorId: 'organization',
            title: 'Organisation',
            content: 'Refers to the entity that owns and manages. Encompasses the various departments, servers, inventory and individuals responsible for implementing, configuring, and maintaining'
        },
        {
            anchorId: 'inventory',
            title: 'Inventory',
            content: 'Comprehensive list of all resources, devices, and users within the network. Serves as the foundation for implementing granular access controls & enforcing the principle of least privilege.'
        },
        {
            anchorId: 'servers',
            title: 'Servers',
            content: 'Creation of new Device System for Server Mode and App Connector accessibility according to respective OS'
        },
        {
            anchorId: 'configure',
            title: 'Configure',
            content: 'Process of setting up and customising to meet specific security and access control requirements which involves defining policies, rules, and configurations'
        },
        {
            anchorId: 'policies',
            title: 'Policies',
            content: 'Outline the specific measures/configuration and controls to safeguard data from unauthorized access, breaches, and data loss.'
        },
        {
            anchorId: 'webfilter',
            title: 'Web filtering',
            content: 'Restricts what websites a user can visit on his or her device  These filters are based on URL, categories, applications with Allow or block.'
        },
        {
            anchorId: 'meshserver',
            title: 'MZA Overlay servers',
            content: 'Also known as software-defined perimeter (SDP) servers, act as intermediaries between users and applications, establishing secure, encrypted connections and enforcing granular access controls.'
        },
        {
            anchorId: 'logs',
            title: 'Logs',
            content: 'Provide a detailed record of user activities, security events, and system operations. Critical for security monitoring, incident response, compliance, & troubleshooting'
        },
        {
            anchorId: 'search',
            title: 'Search',
            content: 'Enables users to quickly and efficiently find the resources they need like specific applications, devices, or users.'
        },
        {
            anchorId: 'devicegroups',
            title: 'Device Groups drop down',
            content: 'List of device groups established/ created  within the tenant.'
        },
        {
            anchorId: 'device',
            title: 'Device list',
            content: 'List of Devices or endpoints registered or established in the tenant'
        },
        {
            anchorId: 'notification',
            title: 'Notification',
            content: 'Notifications for alarms/ issues'
        },
        {
            anchorId: 'support',
            title: 'Support',
            content: 'Facilitates the submission of tickets and issues while allowing for real-time tracking of their status.'
        },
        {
            anchorId: 'setting',
            title: 'Settings',
            content: 'Centralized location to manage your account settings and preferences. Here, you can Manage security settings like,password change, enable multi-factor authentication (MFA), review security policies & Access activity logs'
        },
        {
            anchorId: 'docs',
            title: 'Docs',
            content: 'Your Comprehensive Guide to COSGrid MicroZAccess . Our documentation center provides a wealth of resources to help you get the most out of your Zero Trust Network Access (ZTNA) solution. Access detailed guides, tutorials, and troubleshooting tips'
        },
        {
            anchorId: 'livechat',
            title: 'Live chat',
            content: 'Need immediate assistance with your Zero Trust Network Access? Chat and Connect with our specialists via our user-friendly live chat option.'
        },
        {
            anchorId: 'profile',
            title: 'Profile Status',
            content: 'Shows Organization Tenant Details and Logout from Account'
        }


    ]

    tiles = [];

    routeLinks: any[];
    activeLinkIndex = 0;

    previousDeviceList: DeviceList[] = [];
    devices: DeviceList[] = [];
    claimedDevices: DeviceList[] = [];
    sub: any;

    networkSubscription: Subscription;
    deviceListSubscription: Subscription;

    immedieateFallbackSubscription: Subscription;
    wsDataStreamSubscription: Subscription;

    count = 0;
    dashbaordConf: any;

    id: any;
    statusLoaded = false;

    deviceList = [];
    pollRef: any;

    dashboardSettings: IavailableDashboard

    constructor(
        private http: HttpClient,
        private customerListService: CustomerListService,
        private deviceListService: DeviceListService,
        private globalConstants: GlobalConstants,
        private alert: AlertService,
        private commonservice: CommonService,
        private wsService: WebsocketService,
        private dashboard: DashboardConfService,
        private store: Store<CosgridAppStore>,
        private superAdminListService: SuperAdminListService,
        private joyrideService: JoyrideService,
    ) {

        // if (!this.globalService.isSuperAdmin()) {
        //     this.getDashboardConfig();
        // } else if (this.customerListService.getTenantId()) {
        //     console.log("inside tenant id");

        //     this.getDashboardConfig();
        // }


        this.store.select(state => (state.availableDashboardSlice)).pipe(takeUntil(this.destroy$)).subscribe(value => {
            this.dashboardSettings = value.dashboardSettings
        })

        this.commonservice.changeDashboardEmitter.subscribe(() => {
            this.ngOnDestroy()
            this.wsDataStreamSubscription = undefined
            this.ngOnInit()
        })

    }

    async ngOnInit() {
        // this.checkSessionStorage()
        this.getSettingDashboardValue();
        try {
            // TODO: find a proper solution for triggering networks http call

            this.superAdminListService.dispatchIsTenantSelectedToStore(false); // dispatch will only trigger if the value changes, so making it false and then true
            this.superAdminListService.dispatchIsTenantSelectedToStore(true);
            this.deviceListService.dispatchSelectedDeviceToStore({}); // to make the network dropdown visible and initiate the getNetwork http call
            if (!sessionStorage.getItem('session-dashboard'))
                await this.getDashboardConfig();
            this.subscribeToSelectedNetwork();
            this.getDataFromDataEmitter(); // to get data from data emitter
            this.getALLInfoWs();
            this.subscribeToDevicesSubject();
            this.polling();
            if (this.deviceList.length <= 0 && !localStorage.getItem('tourCompleted'))
                setTimeout(() => {
                    this.startTour()
                }, 1000)


        } catch (err) { }
    }

    checkSessionStorage() {
        let userConf;
        this.store
            .select((state) => state.settingSlice)
            .subscribe((value) => {
                userConf = value.setting;
            });
        const sessionData = sessionStorage.getItem('session-dashboard')
        if (sessionData) {
            const dashboardSettings = {
                dashboardFromPreference: true,
                currentDashboard: JSON.parse(sessionData).name,
                dashboardIcon: JSON.parse(sessionData).icon
            }
            this.store.dispatch({
                type: AVAILABLE_DASHBOARD_ACTION,
                payload: dashboardSettings
            })
            JSON.parse(sessionData).name == 'Zero Trust' ? userConf.vpnDashboard = true : userConf.vpnDashboard = false
            this.store.dispatch({
                type: SETTING_ACTION,
                payload: userConf,
            });
        }
    }

    getDashboardConfig() {
        return new Promise(async (resolve, reject) => {
            try {
                let data: any = await this.dashboard.getDashboardConf().toPromise();
                data.dashboard.devReg_autoApprove = data.devReg_autoApprove

                this.store.dispatch({
                    type: SETTING_ACTION,
                    payload: data.dashboard,
                });
                const dashboardSettings = {
                    dashboardFromPreference: true,
                    currentDashboard: this.dashbaordConf?.vpnDashboard ? 'Zero Trust' : 'SD-WAN',
                    dashboardIcon: this.dashbaordConf?.vpnDashboard ? 'mza-logo' : 'sdwan-logo'
                }
                this.store.dispatch({
                    type: AVAILABLE_DASHBOARD_ACTION,
                    payload: dashboardSettings
                })
                resolve('');
            } catch (error) {
                reject();
            }
        });
    }

    getSettingDashboardValue() {
        this.store
            .select((state) => state.settingSlice)
            .subscribe((value) => {
                this.dashbaordConf = value.setting;
            });
        this.showGraph = this.dashbaordConf?.netflow && !this.dashbaordConf?.vpnDashboard;
    }

    polling() {
        this.interval = setInterval(() => {
            // polling for tenant info
            this.getALLInfoWs();
        }, 10000);
        // }, this.globalConstants.DASHBOARD_POLLING_TIME)
    }

    getDataFromDataEmitter() {
        if (!this.wsDataStreamSubscription) {
            this.subscribeToWsDataStream();
        }
    }

    ngOnDestroy() {
        clearInterval(this.interval);
        this.destroy$.next()
        this.destroy$.complete()
        try {
            this.networkSubscription.unsubscribe();
            this.deviceListSubscription.unsubscribe();
            this.wsDataStreamSubscription.unsubscribe(); // unscubscring from the data emitter so it wont terminate the webscoket
        } catch (err) { }
    }

    subscribeToSelectedNetwork() {
        this.networkSubscription = this.store
            .select((slices) => slices.networkSlice.selectedNetwork)
            .subscribe((res) => {
                if (res.id != this.selectedNetwork.id) {
                    this.selectedNetwork = res;
                    this.getALLInfoWs();
                }
            });
    }
    async getALLInfoWs() {
        try {
            // await this.getDashboardConfig();
            this.getSettingDashboardValue();
            this.wsService.postDataInWs({
                tenant_id: this.customerListService.getTenantId() || '',
                network_id: this.selectedNetwork.id,
                type: this.dashbaordConf?.vpnDashboard ? 'endpoint' : 'device',
            });
            let type = this.dashbaordConf?.vpnDashboard ? 'endpoint' : 'device';
        } catch (error) {
            this.alert.snack('Error occured in settings', 'OK');
            // setTimeout(() => {
            //     this.authservice.logout();
            // }, 2000);
        }
        this.loading = false
    }

    subscribeToWsDataStream() {
        this.wsDataStreamSubscription = this.wsService.getDataEmitter().subscribe(
            (res) => {
                if (res.tenantinfo) {
                    this.tenantinfo = res.tenantinfo;
                    this.tiles = this.getTileInfo();
                }
            },
            (err) => { },
            () => { },
        );
    }

    getAccountInfo() {
        this.http
            .post(this.globalConstants.STATUS_TENANT_INFO, {
                tenantname: this.currentTenantName,
                network_id: this.selectedNetwork,
            })
            .subscribe(
                (res: any) => {
                    const body = res;
                    this.tenantinfo = body;
                },
                (err) => {
                    this.errCount = this.errCount + 1;

                    if (this.errCount > 2) {
                        this.errCount = 0;
                        clearInterval(this.interval);

                        this.pollRef = this.alert.pollingRestart.subscribe(() => {
                            this.getAccountInfo();
                            this.polling();
                        });
                    }
                },
            );
    }

    subscribeToDevicesSubject() {
        this.deviceListSubscription = this.deviceListService.deviceListSubject.subscribe((res) => {
            this.previousDeviceList = [...this.deviceList];
            this.deviceList = res;
            res.length > 0 ? this.deviceAvailable = true : this.deviceAvailable = false
            this.mapLoading = false
        });
    }

    getTileInfo(): ITile[] {
        if (this.dashbaordConf?.vpnDashboard) {
            return [
                {
                    name: 'Total Devices',
                    value: this.tenantinfo.devices,
                    color: '#0078BE',
                    icon: 'tile-device',
                    tourAnchor: 'totaldevices'
                },
                {
                    name: 'Online Devices',
                    value: this.tenantinfo.online_devices,
                    color: '#298C00',
                    icon: 'tile-device',
                    tourAnchor: 'onlinedevices'
                },

                {
                    name: 'Total Users',
                    value: this.tenantinfo.users,
                    color: '#0078BE',
                    icon: 'teams',
                    tourAnchor: 'totalusers'
                },

                {
                    name: 'Online Users',
                    value: this.tenantinfo.online_users,
                    color: '#298C00',
                    icon: 'teams',
                    tourAnchor: 'onlineusers'
                },
            ];
        } else {
            return [
                {
                    name: 'Total Devices',
                    value: this.tenantinfo.devices,
                    color: '#0078BE',
                    icon: 'router',
                },
                {
                    name: 'Deployed Devices',
                    value: this.tenantinfo.deployed_devices,
                    color: '#BB64FF',
                    icon: 'router',
                },
                {
                    name: 'Online Devices',
                    value: this.tenantinfo.online_devices,
                    color: '#298C00',
                    icon: 'router',
                },

                {
                    name: 'Total Links',
                    value: this.tenantinfo.links,
                    color: '#0078BE',
                    icon: 'router',
                },

                {
                    name: 'Active Links',
                    value: this.tenantinfo.active_links,
                    color: '#298C00',
                    icon: 'router',
                },
            ];
        }
    }

    startTour() {
        this.commonservice.emitOpenMenu()
        if (this.dashbaordConf?.vpnDashboard) {
            this.tourService.initialize(this.ztnaTourStep, {
                centerAnchorOnScroll: true,
                enableBackdrop: true,
                disablePageScrolling: true,
                smoothScroll: true,
                backdropConfig: {
                    offset: 10,
                },
            })
            this.tourService.start()
        } else {
            setTimeout(() => {
                this.joyrideService
                    .startTour({
                        steps: [
                            'home',
                            'sdWan',
                            'globalMonitor',
                            'logs',
                            'teams',
                            'teamusers',
                            'group',
                            'ndr',
                            'organization',
                            'inventory',
                            'monitor',
                            'systemTools',
                            'configTemplates',
                            'search',
                            'network',
                            'device',
                            'notifications',
                            'profile',
                        ],
                        stepDefaultPosition: 'right',
                    })
                    .subscribe(
                        (step) => { },
                        (error) => { },
                        () => {
                            // this.commonservice.UITourTransfer.emit('start');
                        },
                    );
            }, 200);

        }
        localStorage.setItem('tourCompleted', JSON.stringify(true))
    }
}
