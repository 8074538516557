import { EventEmitter, inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Route, Router } from '@angular/router';
import { AuthenticationService } from 'app/modules/auth/services/authentication.service';
import { FreeTrailBookingDemoComponent } from 'app/shared/components/free-trail-booking-demo/free-trail-booking-demo.component';
import { CommonService, WebsocketService } from 'app/shared/services/core';
import { IStepOption, TourService } from 'ngx-ui-tour-md-menu';

@Injectable({
  providedIn: 'root'
})
export class UiTourService {
  private readonly tourService = inject(TourService);
  private openedSteps = new Set<string>();
  steps: IStepOption[] = [
    {
      anchorId: 'dashboard',
      title: 'Dashboard',
      content: 'Provides visibility and control over access and user & device activities along with organisation level information overview and its corresponding status'
    },
    {
      anchorId: 'microzaccess',
      title: 'MircoZAccess Endpoint profile ',
      content: 'Creation of endpoint profiles for respective devices with specific accessibility for users or particular devices based on the security policies',
    },
    {
      anchorId: 'networks',
      title: 'Device Groups',
      content: 'Allow administrators to categorize devices based on various criteria, such as device type, operating system, or security posture. Enables granular access control and policy enforcement, enhancing security and compliance.'
    },
    {
      anchorId: 'teams',
      title: 'Teams',
      content: 'Collection of users and/or groups with shared access policies.Used to streamline access management and policy enforcement.'
    },
    {
      anchorId: 'users',
      title: 'Users',
      content: 'Represent human users, service accounts, or other entities that require access to resources.Each user has a unique identity and set of permissions.'
    },
    {
      anchorId: 'groups',
      title: 'Groups',
      content: 'Collection of users that share common access requirements. Simplify access management by defining policies for groups rather than individual users like departments, or roles within an organization.'
    },
    {
      anchorId: 'organization',
      title: 'Organisation',
      content: 'Refers to the entity that owns and manages. Encompasses the various departments, servers, inventory and individuals responsible for implementing, configuring, and maintaining'
    },
    {
      anchorId: 'inventory',
      title: 'Inventory',
      content: 'Comprehensive list of all resources, devices, and users within the network. Serves as the foundation for implementing granular access controls & enforcing the principle of least privilege.'
    },
    {
      anchorId: 'servers',
      title: 'Servers',
      content: 'Creation of new Device System for Server Mode and App Connector accessibility according to respective OS'
    },
    {
      anchorId: 'configure',
      title: 'Configure',
      content: 'Process of setting up and customising to meet specific security and access control requirements which involves defining policies, rules, and configurations'
    },
    {
      anchorId: 'policies',
      title: 'Policies',
      content: 'Outline the specific measures/configuration and controls to safeguard data from unauthorized access, breaches, and data loss.'
    },
    {
      anchorId: 'webfilter',
      title: 'Web filtering',
      content: 'Restricts what websites a user can visit on his or her device  These filters are based on URL, categories, applications with Allow or block.'
    },
    {
      anchorId: 'meshserver',
      title: 'MZA Overlay servers',
      content: 'Also known as software-defined perimeter (SDP) servers, act as intermediaries between users and applications, establishing secure, encrypted connections and enforcing granular access controls.'
    },
    {
      anchorId: 'logs',
      title: 'Logs',
      content: 'Provide a detailed record of user activities, security events, and system operations. Critical for security monitoring, incident response, compliance, & troubleshooting'
    },
    {
      anchorId: 'totaldevices',
      title: 'Total devices',
      content: 'Total number of devices registered to access the network. Provides a snapshot of the overall number of devices that are authorized to connect.'
    },
    {
      anchorId: 'onlinedevices',
      title: 'Online Devices',
      content: 'The number of devices currently connected to the network . Indicates the real-time activity and usage of the COSGrid MicroZAccess'
    },
    {
      anchorId: 'totalusers',
      title: 'Total users',
      content: 'The total number of users registered to access the network. Provides a snapshot of the overall user base.'
    },
    {
      anchorId: 'onlineusers',
      title: 'Online users',
      content: 'The number of users currently active on the network. Indicates real-time user activity and resource utilization.'
    },
    {
      anchorId: 'map',
      title: 'Location',
      content: 'Visualizes the geographic distribution of devices connected to the network. Provides a geographical overview of network activity and helps identify potential security risks or performance issues.'
    },
    {
      anchorId: 'Statusandvisibility',
      title: 'Status and visibility',
      content: 'The total number of users registered to access the network. Provides a snapshot of the overall user base.'
    },
    {
      anchorId: 'search',
      title: 'Search',
      content: 'Enables users to quickly and efficiently find the resources they need like specific applications, devices, or users.'
    },
    {
      anchorId: 'devicegroups',
      title: 'Device Groups drop down',
      content: 'List of device groups established/ created  within the tenant.'
    },
    {
      anchorId: 'device',
      title: 'Device list',
      content: 'List of Devices or endpoints registered or established in the tenant'
    },
    {
      anchorId: 'notification',
      title: 'Notification',
      content: 'Notifications for alarms/ issues'
    },
    {
      anchorId: 'support',
      title: 'Support',
      content: 'Facilitates the submission of tickets and issues while allowing for real-time tracking of their status.'
    },
    {
      anchorId: 'setting',
      title: 'Settings',
      content: 'Centralized location to manage your account settings and preferences. Here, you can Manage security settings like,password change, enable multi-factor authentication (MFA), review security policies & Access activity logs'
    },
    {
      anchorId: 'docs',
      title: 'Docs',
      content: 'Your Comprehensive Guide to COSGrid MicroZAccess . Our documentation center provides a wealth of resources to help you get the most out of your Zero Trust Network Access (ZTNA) solution. Access detailed guides, tutorials, and troubleshooting tips'
    },
    {
      anchorId: 'livechat',
      title: 'Live chat',
      content: 'Need immediate assistance with your Zero Trust Network Access? Chat and Connect with our specialists via our user-friendly live chat option.'
    },
    {
      anchorId: 'profile',
      title: 'Profile Status',
      content: 'Shows Organization Tenant Details and Logout from Account'
    },
    {
      anchorId: 'end',
      title: 'Dashboard',
      content: 'Provides visibility and control over access and user & device activities along with organisation level information overview and its corresponding status'
    },
  ]
  public demoAccount: EventEmitter<string> = new EventEmitter()
  constructor(
    private dialog: MatDialog,
    private commonService: CommonService,
    private authService: AuthenticationService,
    private router: Router,
    private wsService: WebsocketService
  ) {
    this.demoAccount.subscribe(() => {
      setTimeout(() => {
        this.initilizeSteps()
        // this.dialog.open(FreeTrailBookingDemoComponent, { data: { skipNeeded: true, simpleDialog: false } })
      }, 2000)

    })
  }

  initilizeSteps() {
    this.tourService.initialize(this.steps, {
      centerAnchorOnScroll: true,
      enableBackdrop: true,
      disablePageScrolling: true,
      smoothScroll: true,
      backdropConfig: {
        offset: 10,
      },
    })
    this.commonService.openMenuEvent.emit()
    this.tourService.start()
    // this.wsService.terminateConnection()
    this.tourService.stepShow$.subscribe((step) => {
      if (this.openedSteps.has(step.anchorId)) {
        // If the dialog has already been opened for this step, skip the logic
        return;
      }
      switch (step.anchorId) {
        case 'totaldevices':
          this.tourService.pause()
          this.openFreeTrailDialog()
          this.openedSteps.add(step.anchorId);

          break;
        case 'search':
          this.openedSteps.add(step.anchorId);
          this.tourService.pause()
          this.openFreeTrailDialog()
          break;
        case 'end':
          this.openedSteps.add(step.anchorId)
          this.tourService.pause()
          this.openFreeTrailDialog()
          break;
      }
    })
  }

  openFreeTrailDialog() {
    console.log(this.tourService.currentStep.anchorId);

    const dialogRef = this.dialog.open(FreeTrailBookingDemoComponent, { data: { skipNeeded: true, simpleDialog: this.tourService.currentStep.anchorId == 'end' ? false : true } })
    dialogRef.afterClosed().subscribe((res) => {
      console.log("printed");

      if (res == 'signup') {
        this.authService.logout()
        this.router.navigate(['/auth/sign-up'])
        return;
      }
      if (this.tourService.currentStep.anchorId == 'end') {
        const lastDialog = this.dialog.open(FreeTrailBookingDemoComponent, { data: { skipNeeded: false, simpleDialog: true } })
        lastDialog.afterClosed().subscribe((res) => {
          if (res == 'signup') {
            this.authService.logout()
            setTimeout(() => {
              this.router.navigate(['/auth/sign-up'])
            }, 1000)

            return;
          }
        })
        this.tourService.end()
        return
      }
      setTimeout(() => {
        this.tourService.resume()
      }, 300)
    })

  }
}
