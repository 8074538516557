import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'cosgrid-get-micro-zaccess',
    templateUrl: './get-micro-zaccess.component.html',
    styleUrls: ['./get-micro-zaccess.component.scss'],
})
export class GetMicroZaccessComponent implements OnInit {
    constructor(private router: Router, private http: HttpClient) { }

    details;
    loading = false;

    ngOnInit(): void {
        this.loading = true;

        this.http.get('https://cosgridnetworks.in/api/v1/auth/mza-pkg-paths/').subscribe(
            (data) => {
                this.details = data['detail'];
                this.loading = false;
            },
            (er) => {
                console.log(er);
                this.loading = false;
            },
        );
    }
    downloadClick() {
        window.location.href = `https://vcdn.cosgrid.com/website/MicroZAccess/${this.details['Windows']}`;
    }
    downloadMacOSClick() {
        window.location.href = `https://vcdn.cosgrid.com/website/MicroZAccess/${this.details['macOS']}`;
    }
}
