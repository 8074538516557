import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialog } from 'app/shared';

@Component({
  selector: 'cosgrid-logout-dialog',
  templateUrl: './logout-dialog.component.html',
  styleUrls: ['./logout-dialog.component.scss']
})
export class LogoutDialogComponent {
  public title: string;
  public message: string;
  public content: string;

  public configTempDevices: any[] = [];
  public NetworkDevices: any[] = [];

  constructor(public dialogRef: MatDialogRef<ConfirmDialog>, @Inject(MAT_DIALOG_DATA) public data?: any) {
    if (data) {
      try {
        if (data.message) {
          this.content = data.message;
        }
        if (data.devices.length > 0) {
          this.configTempDevices = data.devices;
        }
      } catch (err) { }
      try {
        if (data.deviceList.length > 0) {
          this.NetworkDevices = data.deviceList;
        }
      } catch (err) { }
    }
  }

}
