<ng-container *ngIf="dashbaordConf?.vpnDashboard; else elseTemplate" class="main-screen">
    <div class="row ml-1" *ngIf="deviceAvailable > 0">
        <div id="dashboard" style="flex: 1;">
            <cosgrid-tile-group [tiles]="tiles"></cosgrid-tile-group>
        </div>
        <div class="col" style="flex: 5; margin-left: 15px;padding-right: 0;">
            <map [devices]="deviceList" [mapLoading]="mapLoading"></map>
        </div>
    </div>
</ng-container>
<ng-template #elseTemplate class="main-screen">
    <div id="dashboard" *ngIf="deviceAvailable > 0">
        <cosgrid-tile-group [tiles]="tiles"></cosgrid-tile-group>
    </div>
    <div class="row" *ngIf="deviceAvailable > 0">
        <div class="col" style="margin-top: 5px;">
            <map [devices]="deviceList" [mapLoading]="mapLoading"></map>
        </div>
        <div *ngIf="showGraph" class="col" style="padding-left: 0px !important;padding-right: 0;">
            <div id="graph" class="card" style="margin-top: 15px;">
                <cosgrid-top-devices [networkChange]="selectedNetwork"></cosgrid-top-devices>
            </div>
        </div>
    </div>
</ng-template>

<!--end of row-->
<div class="row ml-3 mb-2 main-screen" style="height: calc(100vh - 100px);"
    [ngClass]="{'d-flex jusify-content-center align-items-center': deviceList.length <= 0}">
    <device-card [dashboardConf]="dashbaordConf"></device-card>
</div>