<style>
    .overflow {
        max-height: 500px;
        overflow-y: scroll;
    }

    #search {
        border: 1.5px solid #dadada;
        border-radius: 5px;
        font-weight: 400;
        font-size: 14px;
        text-decoration: none;
        text-transform: lowercase !important;
        width: 85%;
    }

    #dropdownButton {
        border: 0px solid !important;
        background-color: white;
        padding: 5px;
        margin-right: 12px;
    }

    .textButton {
        border: 0px solid !important;
        background-color: white !important;
        padding: 1px;
        margin-left: 3px;

    }

    .btn-group {
        border: 1px solid lightgray !important;
        border-radius: 5px !important;
        background-color: white;
        height: 25px;
        margin-top: 1.2px;
    }

    input {
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;
    }

    i {
        font-weight: 18px !important;
    }

    .dropdown-item:hover {
        color: #fff !important;
        background-color: #007fea;
    }

    /* @media screen and (max-width: 1200px) {
        .btn-group {
            height: auto !important;
        }
    } */
</style>
<div class="btn-group">
    <button class="textButton" type="button" id="selectdevice" data-container="body" data-toggle="popover"
        title="Select Device" data-placement="bottom" data-trigger="focus" tabindex="0"
        data-content="select device to configure and view status" style="white-space: nowrap;">
        {{selectedDeviceName | truncate:10}}
        <!-- <span class="sr-only"></span> -->
    </button>

    <button type="button" id="dropdownButton" class="dropdown-toggle dropdown-toggle-split clickable"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="sr-only"></span>
    </button>
    <div style="min-width: 200px;" class="dropdown-menu dropdown-menu-right overflow DropdownAnimate slideIn">
        <div class="position-relative mx-2">
            <p class="position-absolute" style="top: .2rem; left: .2rem;">
                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"
                    fill="#000000">
                    <path
                        d="M765-144 526-383q-30 22-65.79 34.5-35.79 12.5-76.18 12.5Q284-336 214-406t-70-170q0-100 70-170t170-70q100 0 170 70t70 170.03q0 40.39-12.5 76.18Q599-464 577-434l239 239-51 51ZM384-408q70 0 119-49t49-119q0-70-49-119t-119-49q-70 0-119 49t-49 119q0 70 49 119t119 49Z" />
                </svg>
            </p>
            <input id="search" autocomplete="off" (keyup)="filterValue($event)" type="text"
                style="padding-left: 1.5rem;" />
        </div>

        <a style="width: 80%; padding: 5px 15px; margin-right: 0px; cursor: pointer;" *ngFor="let device of deviceList"
            (click)="devChanged(device)" class="dropdown-item">
            {{ device?.name || '' }}
            <i matTooltip="Running" matTooltipPosition="right"
                *ngIf="device.deviceStatus === 'running' || device.deviceStatus ===  'connected'"
                style="float: right; color: lawngreen;" class="fas fa-sort-up"></i>
            <i matTooltip="Not Reachable" matTooltipPosition="right"
                *ngIf="device.deviceStatus === 'error' || device.deviceStatus === 'offline'"
                style="float: right; color: orangered;" class="fas fa-sort-down"></i>
            <i matTooltip="Registered" matTooltipPosition="right" *ngIf="device.deviceStatus === 'registered'"
                style="float: right; color: orange;" class="fas fa-check"></i>
        </a>
    </div>
</div>