import { UntypedFormGroup } from '@angular/forms';

export function ValidateForm(formName) {
    return function (target, key, descriptor) {
        const originalMethod: Function = descriptor.value;

        descriptor.value = function (this, ...args) {
            let formGroup: UntypedFormGroup = this[formName];
            if (validateForm(formGroup)) {
                originalMethod.apply(this, args);
            }
        };

        return descriptor;
    };
}
function validateForm(form: UntypedFormGroup): boolean {
    if (!form.valid) {
        for (let i in form.controls) {
            form.controls[i].markAsTouched();
        }
        return false;
    } else {
        return true;
    }
}
