import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConstants } from 'app/configs/constants';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CheckConnectionService {
    internetState = true;

    constructor(private http: HttpClient, private handler: HttpBackend, private globalConstants: GlobalConstants) {
        this.http = new HttpClient(handler);
    }

    isOnline() {
        return new Observable((sub) => {
            this.http.head(`${this.globalConstants.endpointUrl}api/v1/tenant/data/cosgrid-com-mail/`).subscribe(
                (_) => {
                    sub.next();
                    sub.complete();
                },
                (err) => {
                    console.log(err);
                    if (err.statusText === 'Unknown Error') {
                        sub.error();
                        sub.complete();
                    } else {
                        sub.next();
                        sub.complete();
                    }
                },
            );
        });
    }

    isBackendOnline() {
        return this.http.head(`${this.globalConstants.endpointUrl}api/v1/tenant/data/cosgrid-com-mail/`);
    }
}
