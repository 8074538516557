import { ValidatorFn, AbstractControl } from '@angular/forms';
import { IPV4WithOptionalPort } from 'app/configs/constants';

export function ValidateIPWithPort(control: AbstractControl) {
    if (control.value === '') {
        return null;
    } else if (!IPV4WithOptionalPort.test(control.value)) {
        return {
            ipportpattern: true,
        };
    }
    return null;
}
