<style>
    .header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        height: 50px;
        background-color: #0078be;
    }

    .modal-footer .btn-outline-secondary,
    .fa-times {
        /* color: lightgray; */
        border-color: lightgray;
    }

    .modal-content {
        /* color: #fff !important; */
        margin: auto;
        max-width: 21em;
    }

    .modal-content hr {
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid grey;
        margin: 1em 0;
        padding: 0;
    }

    .modal-footer {
        border-top: 0px;
        display: flex;
        justify-content: center;
    }

    .fa-exclamation-triangle {
        color: #c82333;
        opacity: 0.8;
    }

    button {
        border-radius: 5px;
    }

    h4 {
        margin-left: 10px;
    }

    .modal {
        /* background: rgba(0, 0, 0, 0.8) !important; */
        opacity: 1 !important;
    }
</style>
<!-- Modal -->
<div class="d-block modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="header">
                <img src="assets/icons/LOgout (2).png" alt="Logout" width="30px" height="30px">
                <h3 style="font-weight: bold;color: #fff;font-size: 17px;margin: 0; margin-top: 5px; margin-left: 5px;">
                    Log Out Account
                </h3>
            </div>

            <div class="d-flex flex-column align-items-center">
                <img src="assets/icons/COSGrid symbol HD Black .png" alt="Logo" width="60px" height="60px" class="my-2">
                <p>Are you sure you want to log out?</p>
                <p>Once logout you need to login again!</p>
            </div>


            <div class="modal-footer">
                <button type="button" (click)="dialogRef.close()" class="btn btn-primary"
                    style="width: 150px;font-weight: bold;">
                    <!-- <i class="fa fa-check" aria-hidden="true"></i> -->
                    No, Stay logged in
                </button>

                <button id="no" type="button" (click)="dialogRef.close('true')" class="btn" data-dismiss="modal"
                    style="background-color: #ff560b;color: #fff;width: 150px; font-weight: bold;">
                    <!-- <i class="fa fa-times" aria-hidden="true"></i> -->
                    Yes, Logout
                </button>

            </div>
        </div>
    </div>
</div>