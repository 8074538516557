<div *ngIf="dataLength === 0 && !loading">
    <div class="noDataContainer" [ngSwitch]="type">
        <span *ngSwitchCase="'configuration'">
            <img *ngIf="!image" [class]="type"
                src="https://vcdn.cosgrid.com/website/COSGridNet/assets/noData/noConf.png" />
            <img *ngIf="image" [class]="type" [src]="image" />
            <p *ngIf="!message">
                {{ 'Create your First Configuration' }}
            </p>
            <p *ngIf="message">{{message}}</p>
        </span>
        <span *ngSwitchCase="'status'">
            <img *ngIf="!image" [class]="type"
                src="https://vcdn.cosgrid.com/website/COSGridNet/assets/noData/noStatus.png" />
            <img *ngIf="image" [class]="type" [src]="image" />
            <p *ngIf="!message">
                {{ 'No Status Available' }}
            </p>
            <p *ngIf="message">{{message}}</p>
        </span>
        <span *ngSwitchCase="'networks'">
            <img *ngIf="!image" [class]="type"
                src="https://vcdn.cosgrid.com/website/COSGridNet/assets/noData/noNetworks.png" />
            <img *ngIf="image" [class]="type" [src]="image" />
            <p *ngIf="!message">
                {{ 'Create your First Network' }}
            </p>
            <p *ngIf="message">{{message}}</p>
        </span>
        <span *ngSwitchCase="'user'">
            <img *ngIf="!image" [class]="type"
                src="https://vcdn.cosgrid.com/website/COSGridNet/assets/noData/noUser.png" />
            <img *ngIf="image" [class]="type" [src]="image" />
            <p *ngIf="!message">
                {{ 'Create your First User' }}
            </p>
            <p *ngIf="message">{{message}}</p>
        </span>
        <span *ngSwitchCase="'configTemp'">
            <img *ngIf="!image" [class]="type"
                src="https://vcdn.cosgrid.com/website/COSGridNet/assets/noData/noConfigTemplates.png" />
            <img *ngIf="image" [class]="type" [src]="image" />
            <p *ngIf="!message">
                {{ 'Create your First Config Template' }}
            </p>
            <p *ngIf="message">{{message}}</p>
        </span>
    </div>
</div>