import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConstants } from 'app/configs/constants';
import { Observable, ObservableLike } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SupportService {
    constructor(private http: HttpClient, private globalConstants: GlobalConstants) { }
    // url: string = 'https://6876-27-4-7-94.ngrok-free.app'
    url: string = 'https://waf.cosgrid.com'

    postForm(data) {
        // return this.http.post(this.globalConstants.TENANT_SUPPORT, data);
        const headers = new HttpHeaders({
            'x-apikey': '09a9a2fb-010f-4180-aace-af2ff39cfdea'
        })
        return this.http.post(`${this.url}/api/tickets/`, data, { headers })
    }

    getIssues(tenantname) {
        return this.http.post(this.globalConstants.TENANT_SUPPORT, {
            type: 'get',
            tenant_name: tenantname,
        });
    }

    changeIssueStatus(data) {
        return this.http.put(this.globalConstants.TENANT_SUPPORT + data.id + '/', data);
    }

    getAllTickets() {
        const headers = new HttpHeaders({
            'x-apikey': '09a9a2fb-010f-4180-aace-af2ff39cfdea'
        })
        return this.http.get(`${this.url}/api/tickets/`, { headers });
    }

    getTickets(filter: any) {
        const headers = new HttpHeaders({
            'x-apikey': '09a9a2fb-010f-4180-aace-af2ff39cfdea'
        })
        return this.http.get(`${this.url}/api/tickets/?status=${filter.status}&priority=${filter.priority}&start_date=${filter.start_date}&end_date=${filter.end_date}`, { headers });
    }

    getFollowUps(id: number): Observable<any> {
        console.log("follow uos");

        const headers = new HttpHeaders({
            'x-apikey': '09a9a2fb-010f-4180-aace-af2ff39cfdea'
        })
        return this.http.get(`https://waf.cosgrid.com/api/tickets/${id}/`, { headers })
    }

    getDeviceList(data: any): Observable<any> {
        return this.http
            .post(this.globalConstants.DEVICE_LIST, data)
    }
}
