import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TimeformatPipe } from 'app/shared/pipes/timeformat.pipe';

@Component({
    selector: 'app-echart',
    // template: `
    // <div class="mGraph-wrapper">
    //   <div class="mGraph" id="timeSeriesChart" echarts [options]="options"></div>
    //   <div class="details">

    //   </div>
    // </div>`,
    templateUrl: './echart.component.html',
    styleUrls: ['./echart.component.scss'],
})
export class EchartComponent implements OnInit {
    @Input() title: string = 'Time Series';
    @Input() informationBox: { min: boolean; max: boolean; average: boolean; current: boolean } = {
        min: true,
        max: true,
        average: true,
        current: true,
    };
    @Input() names: Array<string> = [];
    @Input() data: Array<Array<any>> = [];
    @Input() axisTypes: Array<string> = ['time', 'value'];
    @Input() legendOrientation: string = 'vertical';
    @Input() chartType: 'line' | 'bar' = 'line';
    @Input() scatterChart: boolean = false;
    @Input() recordDuration: 'seconds' | 'minutes' | 'hours' | 'days' | 'weeks' | 'months' | 'years' | '' = 'hours';
    @Input() yAxisType: 'memory' | 'cpu' | 'roundTrip' | 'dropRate' | 'no-suffix' | 'ms' = 'memory';
    @Input() lineColors: Array<string> = ['#0078be', '#6801af', '#ff560b', '#298c00'];
    @Input() timestamp: Array<string> | Array<number> = [];
    @Input() legend: boolean = true;

    description: Array<any> = [];
    options: any;
    maxByteSize: number = 0;
    dataSize: string = 'b';
    maxTickLen: number = 0;

    constructor(
        private timeFormat: TimeformatPipe
    ) { }

    ngOnInit(): void {
        for (let i = 0; i < this.data.length; i++) this.description.push({ min: 0, max: 0, average: 0, current: 0 });
        this.createDescription();
        this.createChart();
    }

    createDescription(): void {
        this.data.length > 0 ? (this.description = []) : '';
        this.description = this.data.map((data) => ({
            min:
                this.yAxisType == 'memory'
                    ? this.tooltipValue(Math.min(...data))
                    : this.yAxisType == 'cpu' || this.yAxisType == 'dropRate'
                        ? this.cpuTooltip(Math.min(...data))
                        : Math.min(...data).toFixed(0) + ' ms',
            max:
                this.yAxisType == 'memory'
                    ? this.tooltipValue(Math.max(...data))
                    : this.yAxisType == 'cpu' || this.yAxisType == 'dropRate'
                        ? this.cpuTooltip(Math.max(...data))
                        : Math.max(...data).toFixed(0) + ' ms',
            average:
                this.yAxisType == 'memory'
                    ? this.tooltipValue(data.reduce((a, b) => a + b, 0) / data.length)
                    : this.yAxisType == 'cpu' || this.yAxisType == 'dropRate'
                        ? this.cpuTooltip(data.reduce((a, b) => a + b, 0) / data.length)
                        : (data.reduce((a, b) => a + b, 0) / data.length).toFixed(0) + ' ms',
            current:
                this.yAxisType == 'memory'
                    ? this.tooltipValue(data[data.length - 1])
                    : this.yAxisType == 'cpu' || this.yAxisType == 'dropRate'
                        ? this.cpuTooltip(data[data.length - 1])
                        : data[data.length - 1].toFixed(0) + ' ms',
        }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && !changes.data.firstChange) {
            this.createDescription();
            this.createChart();
            console.log(this.options);

        }
    }

    createChart(): void {
        this.maxByteSize = Math.max(...this.data.flat());
        this.options = {
            animation: false,
            color: this.lineColors,
            title: {
                text: this.title,
            },
            tooltip: {
                trigger: 'axis',
                textStyle: {
                    fontSize: 13,
                    lineHeight: 21,
                },
                backgroundColor: 'rgba(52, 58, 64, 0.95)',
                formatter: (params: any) => {
                    const date = new Date(params[0].axisValue).toLocaleString();
                    const seriesInfo = params
                        .map(
                            (param: any) =>
                                `<tr>
              <td>${param.marker} <span style="color:rgba(240, 240, 240, 0.7)">${param.seriesName}</span></td>
              <td><b>${this.yAxisType == 'memory'
                                    ? this.tooltipValue(param.value[1])
                                    : this.yAxisType == 'cpu' || this.yAxisType == 'dropRate'
                                        ? this.cpuTooltip(param.value[1])
                                        : this.yAxisType == 'ms' ? param.value[1] + ' ms' : param.value[1]
                                }</b></td>
            </tr>`,
                        )
                        .join(''); // Added .join('') to convert array to string
                    return `${date}<br><table>${seriesInfo}</table>`;
                },
            },
            xAxis: [
                {
                    type: this.axisTypes[0],
                    data: this.timestamp,
                    axisLine: { show: false },
                    splitLine: { show: false },
                    splitNumber: 10,
                    axisLabel: {
                        color: '#6d7680',
                        formatter: (value: string, index: number) => {
                            // if (index == 0 || index === this.timestamp.length - 1) {
                            //     return '';
                            // }
                            // const date = this.timeFormat.transform(value, '');
                            switch (this.recordDuration) {
                                case 'seconds':
                                    return this.timeFormat.transform(value, 'mm:ss')
                                case 'minutes':
                                case 'hours':
                                    return this.timeFormat.transform(value, 'HH:mm');
                                case 'days':
                                case 'weeks':
                                    return this.timeFormat.transform(value, 'MMM DD')
                                case 'months':
                                    return this.timeFormat.transform(value, 'MMM YY')
                                case 'years':
                                    return this.timeFormat.transform(value, 'YYYY');
                                default:
                                    return value;
                            }
                        },
                    },
                },
            ],
            yAxis: [
                {
                    type: this.axisTypes[1],
                    axisLine: { show: false },
                    splitNumber: 5,
                    axisTick: {
                        length: 5,
                    },
                    splitLine: {
                        show: true,
                        lineStyle: { color: ['#e9ecef'] },
                    },
                    axisLabel: {
                        color: '#6d7680',
                        formatter:
                            this.yAxisType == 'memory'
                                ? (value: number) => {
                                    if (value >= 1073741824) return (value / 1073741824).toFixed(1) + ' Gbps';
                                    else if (value >= 1048576) return (value / 1048576).toFixed(2) + ' Mbps';
                                    else if (value >= 1024) return (value / 1024).toFixed(1) + ' Kbps';
                                    else return value + ' Bps';
                                }
                                : this.yAxisType == 'cpu' || this.yAxisType == 'dropRate'
                                    ? (value: number) => {
                                        return value.toFixed(2) + ' %';
                                    }
                                    : this.yAxisType == 'no-suffix'
                                        ? (value: number) => {
                                            return value;
                                        }
                                        : (value: number) => {
                                            return value + ' ms';
                                        }
                    },
                },
            ],
            legend: {
                show: this.legend,
                type: 'scroll',
                orient: this.legendOrientation,
                right: '0',
                top: 'middle',
                formatter: (name: string) => (name.length > 15 ? `${name.slice(0, 15)}...` : name),
                icon: 'roundRect',
                backgroundColor: '#fff',
            },
            toolbox: {
                show: true,
                feature: {
                    // restore: { show: true, title: 'Restore' },
                    // dataView: { show: true, title: 'Data View' },
                },
            },
            series: this.getSeriesData(),
        };
    }

    findSize(value: number) {
        if (this.maxByteSize >= 1048576) return (value / 1048576).toFixed(2) + ' Mbps';
        else if (this.maxByteSize >= 1024) return (value / 1024).toFixed(2) + ' Kbps';
        else return value + ' Bps';
    }

    tooltipValue(value: number) {
        if (value >= 1073741824) return (value / 1073741824).toFixed(1) + ' Gbps';
        else if (value >= 1048576) return (value / 1048576).toFixed(2) + ' Mbps';
        else if (value >= 1024) return (value / 1024).toFixed(1) + ' Kbps';
        else return value + ' Bps';
    }

    getSeriesData() {
        return this.names.map((name, index) => ({
            id: index,
            name: name || 'Time Series Graph',
            type: this.chartType,
            showSymbol: false,
            data: this.timestamp.map((timestamp, i) => [timestamp, this.data[index][i]]),
            lineStyle: {
                color: this.lineColors[index % this.lineColors.length],
                width: this.scatterChart ? 1 : 2,
            },
            areaStyle: {
                color: this.lineColors[index % this.lineColors.length],
                opacity: this.scatterChart ? 0.1 : 0,
            },
        }));
    }

    cpuTooltip(value: number) {
        return value.toFixed(3) + ' %';
    }
}
