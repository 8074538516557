import { HttpClient, HttpParams, HttpBackend, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class RealtimeChartService {
    constructor(private http: HttpClient, private handler: HttpBackend) {
        this.http = new HttpClient(this.handler);
    }
    influxURL = 'https://cosgridnetworks.com/influx/v2/query?';

    // caching realtime data
    realtimeCache: any = {};

    queryInflux(id, instance) {
        const query = `SELECT non_negative_derivative(mean("value"), 1s) *8 FROM "interface_0" WHERE ("host" = '${id}' AND "instance" = '${instance}' AND "type" = 'if_octets') AND time >= now() - 200s GROUP BY time(1s) fill(none)%3BSELECT non_negative_derivative(mean("value"), 1s) *8 FROM "interface_1" WHERE ("host" = '${id}' AND "instance" = '${instance}' AND "type" = 'if_octets') AND time >= now() - 200s GROUP BY time(1s) fill(none)`;
        const params = new HttpParams().set('db', 'collectd').set('q', query);
        const url = (this.influxURL + params).replace('%253B', '%3B');
        return this.http.get(url, {
            headers: new HttpHeaders({
                Authorization:
                    'Token rLrLfG9vEHpWsMIoDWI9m7i9pLUDD9Tq5xU8BWAfiprCS9ZvcLNRuU8j67_uM3zW7c7hi0SoTXEdyCYAcdMY0g==',
            }),
        });
    }
}
