<div class="w-100 h-50 d-flex flex-column align-items-center justify-content-center">
  <img src="https://vcdn.cosgrid.com/COSGridNet/assets/error.png" alt="" width="70px" />
  <p class="h4 mt-3">{{ errHeading }}</p>
  <p style="font-size: 13px;">{{ errMessage }}</p>
  <p class="text-center" style="font-size: 12px;">
    Please
    <span (click)="refreshPage()" *ngIf="refresh"><a (click)="refreshPage()" class="refresh">refresh the page</a>
      or</span>
    {{ errRequest }}
  </p>
</div>