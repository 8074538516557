<ng-container *ngIf="vpnDashboard; else elseTemplate">
    <div *ngFor="let tile of tiles;let index=index; let odd=odd;" class="col" [tourAnchor]="tile.tourAnchor">
        <div class="banner" [ngClass]="odd ? 'odd' : 'even'"></div>
        <div class="vpn-box-container">
            <div
                style="text-align: center;display: flex;justify-content: between;align-items: center;width: 100%;gap: 20px;">
                <div *ngIf="tile.value == null" class="client-server" style="height: 4rem">
                    <div class=" loaderRound "> </div>
                </div>
                <div *ngIf="tile.value != null"
                    style="height: 50px;width: 50px;align-items: center;display: flex;justify-content: center;border-radius: 50%;margin-left: 15px;"
                    [ngStyle]="{'background':  tile.color }">
                    <mat-icon [svgIcon]="tile.icon"></mat-icon>
                </div>
                <div *ngIf="tile.value != null">
                    <h6 style="font-weight: 600;">{{ tile.name }}</h6>
                    <h1 style="color: #0078BE;">{{ tile.value }}</h1>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #elseTemplate>
    <div class="ml-3 mt-2 row">
        <div style="width: 19.6%;" *ngFor="let tile of tiles;let index=index; let odd=odd;">
            <div class="banner" [ngClass]="odd ? 'odd' : 'even'"></div>
            <div class="box-container">
                <div
                    style="text-align: center;display: flex;justify-content: center;align-items: center;width: 100%;gap: 20px;">
                    <div *ngIf="tile.value == null" class="client-server" style="height: 3.5rem">
                        <div class=" loaderRound "> </div>
                    </div>

                    <div *ngIf="tile.value != null"
                        style="height: 50px;width: 50px;align-items: center;display: flex;justify-content: center;border-radius: 50%;"
                        [ngStyle]="{'background':  tile.color }">
                        <mat-icon [svgIcon]="tile.icon"></mat-icon>
                    </div>
                    <div *ngIf="tile.value != null">
                        <h6 style="font-weight: 600;">{{ tile.name }}</h6>
                        <h1 style="color: #0078BE;">{{ tile.value }}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>