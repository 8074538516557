import { ValidatorFn, AbstractControl } from '@angular/forms';
import { IPV4 } from 'app/configs/constants';

export function ValidateIP(control: AbstractControl) {
    if (control.value === '') {
        return null;
    } else if (!IPV4.test(control.value)) {
        return {
            ippattern: true,
        };
    }
    // else if (control.value === '0.0.0.0' || control.value === '255.255.255.255') {
    //     return {
    //         ippattern: true,
    //     };
    // }
    return null;
}
