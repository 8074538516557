<label *ngIf="name" [class.required]="isRequired">
    {{ name }}
    <Tooltip *ngIf="needTooltip" [description]="tooltipDescription"></Tooltip>
</label>
<ng-container *ngIf="multiple == true">
    <mat-select (openedChange)="openedChange($event)" class="{{ 'form-control form-control-sm ' + className }}"
        [ngClass]="{
        'is-invalid': input.invalid && input.dirty,
        'is-valid': isRequired && !input.invalid && input.dirty,
        'disabled': disabled
    }" type="text" [formControl]="input" multiple (selectionChange)="optionChanged($event)" [disabled]="disabled"
        [placeholder]="placeholder">
        <mat-option *ngIf="needSearch">
            <ngx-mat-select-search [formControl]="searchInput"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngIf="default" value="{{defaultValue}}">{{default}}</mat-option>
        <mat-option (onSelectionChange)="selectionChange($event)" *ngFor="let item of options"
            [value]="valueKey ? item[valueKey] : item">
            <span *ngIf="dataWithIcon" [innerHTML]="viewKey?item[viewKey]:item"></span>
            <span *ngIf="!dataWithIcon">{{viewKey?item[viewKey]:item}}</span>
        </mat-option>

    </mat-select>
    <small class="label" *ngIf="description">{{description}}</small>
    <small *ngIf="input.invalid && (input.dirty || input.touched)">
        <mat-error>
            <ul>
                <li *ngIf="input['errors']['required']">Field is required</li>
            </ul>
        </mat-error>
    </small>
</ng-container>

<ng-container *ngIf="multiple == false">
    <mat-select (openedChange)="openedChange($event)" (selectionChange)="optionChanged($event)"
        class="{{'form-control form-control-sm '+className}}" [ngClass]="{'is-invalid':input.invalid && (input.dirty),
  'is-valid': isRequired && !input.invalid  && (input.dirty)}" type="text" [formControl]="input"
        [placeholder]="placeholder">
        <mat-option *ngIf="needSearch">
            <ngx-mat-select-search [formControl]="searchInput"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngIf="default" value="{{defaultValue}}" [innerHTML]="default"></mat-option>
        <mat-option (onSelectionChange)="selectionChange($event)" *ngFor="let item of options"
            [value]="valueKey?item[valueKey]:item">
            <span *ngIf="dataWithIcon" [innerHTML]="viewKey?item[viewKey]:item"></span>
            <span *ngIf="!dataWithIcon">{{viewKey?item[viewKey]:item}}</span>
        </mat-option>
    </mat-select>
    <small class="label" *ngIf="description">{{description}}</small>
    <small *ngIf="input.invalid && (input.dirty || input.touched)">
        <mat-error>
            <ul>
                <li *ngIf="input['errors']['required']">Field is required</li>
            </ul>
        </mat-error>
    </small>
</ng-container>