<label [class.required]="isRequired">
  Name
  <!-- <Tooltip
  [description]="'Name of the configuration. \n Can include a-z, A-Z, 1-n, underscore'"
  [note]="'config_name'"
  ></Tooltip> -->
</label>
<input autcomplete="off" class="{{'form-control form-control-sm '+className}}" [ngClass]="{'is-invalid':name.invalid && (name.dirty || name.touched),
'is-valid': isRequired && !name.invalid  && (name.dirty || name.touched)}" type="text" [formControl]="name"
  name="{{currentFormControlName}}" placeholder="{{placeholder}}">
<small class="label">
  {{description}}
</small>
<small *ngIf="name.invalid && (name.dirty || name.touched)">
  <mat-error>
    <ul>
      <li *ngIf="name['errors']['required']">Name is required</li>
      <li *ngIf="name['errors']['pattern']">Name should only contain alphabets, numbers and '_' </li>
      <li *ngIf="name['errors']['notUnique']">Name should be unique from the other configurations </li>
    </ul>
  </mat-error>
</small>