<div *ngIf="!remembermeLoader" style="display: flex; height: 100vh; background-color: #242424;">
    <div style="margin: auto;">
        <img style="display: block;" src="https://vcdn.cosgrid.com/website/COSGridNet/assets/cosgrid-logo.png"
            alt="COSGrid Networks" />
        <img style="display: block; margin-left: 40px; width: 150px; height: 150px;"
            src="https://vcdn.cosgrid.com/website/COSGridNet/assets/load1.gif" alt="loading" />
        <!--  -->
    </div>
</div>

<div *ngIf="remembermeLoader" class="main-screen" tourAnchor="end">
    <div *ngIf="isLoggedIn">
        <cosgrid-header></cosgrid-header>
    </div>
    <div [ngClass]="{ contain: !isLoggedIn, containWidth: isLoggedIn }" class="contain">
        <div class="sidemenu" *ngIf="isLoggedIn">
            <cossgrid-menu></cossgrid-menu>
        </div>
        <div class="routerOutlet">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<tour-step-template></tour-step-template>