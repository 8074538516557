import { CosgridAppStore } from 'app/store/app.store';
import { Store } from '@ngrx/store';
import { Component, Input, OnInit } from '@angular/core';

export interface ITile {
    name: string;
    value: string;
    icon: string;
    color: string;
    tourAnchor?: string;
}

@Component({
    selector: 'cosgrid-tile-group',
    templateUrl: './tile-group.component.html',
    styleUrls: ['./tile-group.component.scss'],
})
export class TileGroupComponent implements OnInit {
    @Input() tiles: ITile[] = [];
    vpnDashboard;
    constructor(private store: Store<CosgridAppStore>) { }

    ngOnInit(): void {
        this.store
            .select((state) => state.settingSlice)
            .subscribe((value) => {
                this.vpnDashboard = value.setting?.vpnDashboard;
                if (this.vpnDashboard) {
                    this.tiles = [
                        {
                            name: 'Total Devices',
                            value: null,
                            color: '#0078BE',
                            icon: 'tile-device',
                        },
                        {
                            name: 'Online Devices',
                            value: null,
                            color: '#298C00',
                            icon: 'tile-device',
                        },

                        {
                            name: 'Total Users',
                            value: null,
                            color: '#0078BE',
                            icon: 'teams',
                        },

                        {
                            name: 'Online Users',
                            value: null,
                            color: '#298C00',
                            icon: 'teams',
                        },
                    ];
                } else {
                    this.tiles = [
                        {
                            name: 'Total Devices',
                            value: null,
                            color: '#0078BE',
                            icon: 'router',
                        },
                        {
                            name: 'Deployed Devices',
                            value: null,
                            color: '#BB64FF',
                            icon: 'router',
                        },
                        {
                            name: 'Online Devices',
                            value: null,
                            color: '#298C00',
                            icon: 'router',
                        },

                        {
                            name: 'Total Links',
                            value: null,
                            color: '#0078BE',
                            icon: 'router',
                        },

                        {
                            name: 'Active Links',
                            value: null,
                            color: '#298C00',
                            icon: 'router',
                        },
                    ];
                }
            });
    }

}
