import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'cosgrid-configure-modal-header',
    templateUrl: './configure-modal-header.component.html',
    styleUrls: ['./configure-modal-header.component.scss'],
})
export class ConfigureModalHeaderComponent implements OnInit {
    @Input() data;
    @Input() name;
    @Input() plainHeader = false
    constructor() { }

    ngOnInit() { }
}
