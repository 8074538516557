import {
    Component,
    OnDestroy,
    AfterViewInit,
    Input,
    forwardRef,
    Injector,
    Self,
    Optional,
    SimpleChanges,
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
    NgControl,
    AbstractControl,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { AlertService } from 'app/shared/services/misc';
import { ValidateIP } from 'app/shared/validators/ipValidator';
import { ENTER, COMMA, SPACE } from '@angular/cdk/keycodes';
import { IPV4 } from 'app/configs/constants';
import { MatChipInputEvent } from '@angular/material/chips';
import { ValidatePort } from 'app/shared/validators/portValidator';
import { ValidateSubnet } from 'app/shared/validators/subnetValidator';
import { ValidateURL } from 'app/shared/validators/urlValidators';
import { ValidateEmail } from 'app/shared/validators/emailValidator';
import { ValidateIPWithPort } from 'app/shared/validators/ipWithPortValidator';

@Component({
    selector: 'config-multi',
    templateUrl: './config-multi.component.html',
    styleUrls: ['./config-multi.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ConfigMultiComponent),
            multi: true,
        },
    ],
})

/*
if the component recieved a formcontrol binding, then we can just use formcontrol directly and modify here since this component also uses formcontrol.
all the changes made to this formcontrol will reflect on the formControl of the parent component because we are just storing the refernce of the
formcontrol we got from parent component.

if the component recieved a ngModel binding, we have to manually change the value of the ngModel binding if the value of the formcontrol
changes in this component.

*/
export class ConfigMultiComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
    @Input() className: string = '';
    @Input() name: string = '';
    @Input() type: 'ip' | 'port' | 'subnet' | 'ipsub' | 'url' | 'email' | 'text' | 'ip-port' = 'ip';
    @Input() uniqueName: string;

    @Input() placeholder = '';
    @Input() description = '';
    @Input() format;
    @Input() note = '';

    onChange: Function;
    onTouched: Function;

    valueChangeSub: Subscription;

    multi = new UntypedFormControl([]);

    data = new UntypedFormControl('');

    isNgModel: boolean = false;
    isRequired: boolean = false;

    isTyping: boolean = false;
    separatorKeysCodes = [ENTER, COMMA, SPACE];
    needLabel: boolean = true;

    constructor(
        @Self()
        @Optional()
        private injector: Injector,
        private alert: AlertService,
    ) { }

    ngAfterViewInit() {
        const ngControl: NgControl = this.injector.get(NgControl, null);
        if (ngControl) {
            this.multi = ngControl.control as UntypedFormControl;
            if (this.hasValidators(this.multi)) {
                this.isRequired = true;
            } else {
                this.isRequired = false;
            }
            this.updateValidation();
        } else {
            this.isNgModel = true;
            this.subscribeToValueChanges();
            // Component is missing form control binding
        }
    }

    ngOnDestroy() {
        if (this.isNgModel) {
            this.valueChangeSub.unsubscribe();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('type' in changes) {
            this.updateValidation();
        }
    }
    updateValidation() {
        if (this.type === 'ip') {
            this.data.setValidators([ValidateIP]);
        } else if (this.type === 'port') {
            this.data.setValidators([ValidatePort]);
        } else if (this.type === 'subnet') {
            this.data.setValidators([ValidateSubnet]);
        } else if (this.type === 'url') {
            this.data.setValidators([ValidateURL]);
        } else if (this.type === 'email') {
            this.data.setValidators([ValidateEmail]);
        } else if (this.type === 'ip-port') {
            this.data.setValidators([ValidateIPWithPort])
        }
    }

    onPaste(e) {
        e.preventDefault();
        var pastedData = e.originalEvent.clipboardData.getData('text');
        this.sanitizeInput(pastedData); // if ips are pasted on bulk sanitize the input as a array and push it coz angular material by default wont handle this
    }

    sanitizeInput(text: string) {
        // *********TODO: use single replace for all specifiers using array****/
        text = text.replace(';', ',');
        text = text.replace(' ', ',');
        text = text.replace(/\n/gi, ',');

        let finalArray = text.split(',');

        finalArray = finalArray.map((item) => item.trim()).filter((item) => item !== '');
        let arrayLength = finalArray.length;
        let wrongIps: number = 0;
        let sanitizedArray = [];
        for (var i = 0; i < arrayLength; i++) {
            if (IPV4.test(finalArray[i])) sanitizedArray.push(finalArray[i]);
            else wrongIps++;
        }
        this.multi.setValue(sanitizedArray);
        if (wrongIps > 1) {
            this.alert.snack(
                `You Have Pasted ${wrongIps} Invalid IPs and ${finalArray.length - wrongIps} Valid IPs`,
                'OK',
            );
        } else {
            this.alert.snack(`You Have Pasted ${finalArray.length} ${finalArray.length === 1 ? 'IP' : 'IPs'}`, 'OK');
        }
        if (this.isNgModel) {
            this.onChange(this.multi.value);
        }
    }

    clearAll() {
        while (this.multi.value.length) {
            this.multi.value.pop();
        }
        // this.multi.setValue([])
        this.onChange(this.multi.value);
    }

    add_item(event: MatChipInputEvent) {
        let input = event.input;
        let value = event.value;
        if ((value || '').trim()) {
            if (this.type === 'ip') {
                if (!ValidateIP(this.data)) {
                    this.multi.value.push(value);
                } else {
                    this.alert.snack('Please Enter a valid IP', 'OK');
                    this.data.setValue('');
                }
            } else if (this.type === 'port') {
                if (!ValidatePort(this.data)) {
                    this.multi.value.push(value);
                } else {
                    this.alert.snack('Please Enter a valid port or port range', 'OK');
                    this.data.setValue('');
                }
            } else if (this.type === 'subnet') {
                if (!ValidateSubnet(this.data)) {
                    this.multi.value.push(value);
                } else {
                    this.alert.snack('Please Enter a subnet', 'OK');
                    this.data.setValue('');
                }
            } else if (this.type === 'ip-port') {
                if (!ValidateIPWithPort(this.data)) {
                    this.multi.value.push(value);
                } else {
                    this.alert.snack('Please Enter a IP or IP with Port', 'OK');
                    this.data.setValue('');
                }
            }
            else {
                if (this.data.valid) {
                    this.multi.value.push(value);
                }
            }

            this.onChange(this.multi.value);
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove_item(ev: any) {
        let index = this.multi.value.indexOf(ev);
        if (index >= 0) {
            this.multi.value.splice(index, 1);
        }
        if (this.data.value.length < 1) {
            this.data.setValue('');
        }
        this.onChange(this.multi.value);
    }

    subscribeToValueChanges() {
        this.valueChangeSub = this.multi.valueChanges.subscribe((res) => {
            this.onChange(res);
        });
    }

    writeValue(value: string): void {
        if (value !== undefined) {
            this.multi.setValue(value);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    hasValidators(control: AbstractControl): boolean {
        return !!control.validator;
    }
}
