<label *ngIf="name" [class.required]="isRequired"> {{name}}
  <!-- <Tooltip 
  [description]="description" 
  [format]="format"
  [note]="note"
  [preFill]="'subnet'"></Tooltip> -->
</label>
<div style="width:100%">
  <small class="clearAll" (click)="clearAll()" *ngIf="multi.value.length > 1">Clear All</small>
  <mat-form-field floatLabel="never" style="width:100%; ">
    <mat-chip-list class="form-control form-control-sm col-11 customList"
      [ngClass]="{'is-invalid':(multi.invalid || data.invalid ) && (data.touched || data.dirty || multi.touched || multi.dirty ) }"
      #chipList1>
      <mat-chip class="customChip" *ngFor="let ip of multi.value" [removable]="true" (removed)="remove_item(ip)"
        required>
        {{ip}}
        <mat-icon matChipRemove> cancel</mat-icon>
      </mat-chip>
      <input [id]="uniqueName" placeholder="&nbsp;&nbsp;{{placeholder}}" [formControl]="data" autocomplete="off"
        [matChipInputFor]="chipList1" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add_item($event)" />
    </mat-chip-list>
  </mat-form-field>
  <small class="label" *ngIf="description">{{description}}</small>
  <small *ngIf="multi.invalid && (data.dirty || data.touched || multi.touched || multi.dirty )">
    <mat-error>
      <ul>
        <li *ngIf="multi['errors']['required']">Field is required. </li>
      </ul>
    </mat-error>
  </small>
  <small *ngIf="data.invalid && (data.dirty || data.touched || multi.touched || multi.dirty )">
    <mat-error>
      <ul>
        <li *ngIf="data['errors']['ippattern']">Format 'x.x.x.x',where x is in the range 0-255</li>
        <li *ngIf="data['errors']['subpattern']">Format 'x.x.x.x/y',where x is in the range 0-255 <br>
          and y in the range 1-32
        </li>

        <li *ngIf="data['errors']['incorrectPort']">Port should be within 1-65535</li>
        <li *ngIf="data['errors']['incorrectRange']">Port range be seperated with ':'</li>
        <li *ngIf="data['errors']['incorrectRange']">Port range should be in ascending </li>

        <li *ngIf="data['errors']['incorrectUrl']">Enter correct URL</li>

        <li *ngIf="data['errors']['incorrectEmail']">Enter valid Email</li>

        <li *ngIf="data['errors']['ipportpattern']">Format 'x.x.x.x' or 'x.x.x.x:yyyy',where x is in the range 0-255 and
          y within 1-65535</li>
      </ul>
    </mat-error>
  </small>
</div>