import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';
import { RealtimeChartService } from 'app/shared/services/comp';
@Component({
    selector: 'cosgrid-traffic-realtime-chart',
    templateUrl: './traffic-realtime-chart.component.html',
    styleUrls: ['./traffic-realtime-chart.component.scss'],
})
export class TrafficRealtimeChartComponent implements OnInit {
    @Input() data: any;
    @Input() wanIndex: any;

    // local Variables
    noOfPoints = 20;
    graphHeight = 16;
    graphWidth = 96;
    uid: string;
    graphData = {
        up: [
            {
                x: 0,
                y: 0,
            },
        ],
        down: [
            {
                x: 0,
                y: 0,
            },
        ],
    };
    upLatestValue = 0;
    downLatestValue = 0;
    upPoints = '';
    downPoints = '';
    isData = false;
    constructor(public realtimeChartService: RealtimeChartService) { }

    ngOnInit() {
        this.setUid();
        this.getDataFromCache();
        if (this.data) {
            if (this.data.deviceStatus === 'running') {
                if (this.data.wan_status[this.wanIndex].status === 'up') {
                    this.queryInflux();
                }
            }
        }
    }

    getDataFromCache() {
        try {
            if (this.uid in this.realtimeChartService.realtimeCache) {
                this.upLatestValue = this.realtimeChartService.realtimeCache[this.uid].upLatestValue;
                this.downLatestValue = this.realtimeChartService.realtimeCache[this.uid].downLatestValue;
                this.upPoints = this.realtimeChartService.realtimeCache[this.uid].upPoints;
                this.downPoints = this.realtimeChartService.realtimeCache[this.uid].downPoints;
            } else {
                this.realtimeChartService.realtimeCache[this.uid] = {};
            }
        } catch (err) { }
    }
    setUid() {
        this.uid = this.data.name + this.data.wan_status[this.wanIndex].ifname;
    }
    queryInflux() {
        this.isData = true;
        this.realtimeChartService.queryInflux(this.data.id, this.data.wan_status[this.wanIndex].ifname).subscribe(
            (res) => {
                this.parseData(res);
            },
            (err) => {
                console.log(err);
            },
        );
    }

    parseData(res) {
        const data = res.results;

        try {
            const up = data[1].series[0].values.map((inp) => inp[1]);

            [this.graphData.up, this.upLatestValue] = this.normalizeData(up);
            this.upPoints = this.addToPoints(this.graphData.up);

            this.cacheData('up');
        } catch (err) {
            this.upLatestValue = 0;
            this.cacheDataIfError('up');
        }
        try {
            const down = data[0].series[0].values.map((inp) => inp[1]);
            [this.graphData.down, this.downLatestValue] = this.normalizeData(down);
            this.downPoints = this.addToPoints(this.graphData.down);
            this.cacheData('down');
        } catch (err) {
            this.downLatestValue = 0;
            this.cacheDataIfError('down');
        }
    }

    normalizeData(data) {
        const returnData = [];
        let dataLength = data.length;
        let latestValue, minVal;
        if (dataLength === 0) {
            latestValue = 0;
            dataLength = 10;
            minVal = 0;
        } else {
            latestValue = data[dataLength - 1];
            minVal = dataLength - this.noOfPoints;
            dataLength = dataLength - 1; // reducing 1 to compensate for length and last index;
        }
        let xVal = this.graphWidth;

        const eachPoint = Math.round(xVal / this.noOfPoints);

        for (let x = dataLength; x >= minVal; x--) {
            if (data[x] === undefined) {
                returnData.unshift({
                    x: (xVal -= eachPoint),
                    y: data[x],
                });
            } else {
                returnData.unshift({
                    x: (xVal -= eachPoint),
                    y: data[x],
                });
            }
        }
        const ratio = _.maxBy(returnData, 'y').y / this.graphHeight;

        return [
            returnData.map((inp) => {
                return {
                    x: inp.x,
                    y: inp.y / ratio,
                };
            }),
            latestValue,
        ];
    }

    addToPoints(data) {
        let points = '';

        data.forEach((inp) => {
            points = points + ' ' + inp.x + ' ' + inp.y;
        });
        return points;
    }

    cacheData(type) {
        if (type === 'up') {
            this.realtimeChartService.realtimeCache[this.uid]['upLatestValue'] = this.upLatestValue;
            this.realtimeChartService.realtimeCache[this.uid]['upPoints'] = this.upPoints;
        } else {
            this.realtimeChartService.realtimeCache[this.uid]['downLatestValue'] = this.downLatestValue;
            this.realtimeChartService.realtimeCache[this.uid]['downPoints'] = this.downPoints;
        }
    }

    cacheDataIfError(type) {
        if (type === 'up') {
            this.realtimeChartService.realtimeCache[this.uid]['upLatestValue'] = 0;
            this.realtimeChartService.realtimeCache[this.uid]['upPoints'] = '0';
        } else {
            this.realtimeChartService.realtimeCache[this.uid]['downLatestValue'] = 0;
            this.realtimeChartService.realtimeCache[this.uid]['downPoints'] = '0';
        }
    }
}
