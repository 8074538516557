import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';
import { Device } from 'app/shared/model';
import { GlobalConstants } from 'app/configs/constants';
import * as _ from 'lodash';
import { CustomerListService, DeviceListService } from 'app/shared/services/comp';
import { Router } from '@angular/router';
import { AlertService } from 'app/shared/services/misc';
import { NetworkListService } from 'app/store/services/network-list.service';
import { allNetworks } from 'app/store/state/network.state';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { CosgridAppStore } from 'app/store/app.store';
import { SET_SELECTED_TENANT } from 'app/store/actions/shared.actions';
import { EncryptedLocalStorageService } from 'app/core/services/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    public UITourTransfer: EventEmitter<any> = new EventEmitter();
    public openMenuEvent: EventEmitter<void> = new EventEmitter<void>();
    public DeviceList: BehaviorSubject<Device[]> = new BehaviorSubject<Device[]>([]);
    public selectedDevice: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public deviceSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public changeDashboardEmitter: EventEmitter<string> = new EventEmitter<string>();
    public profileUpdatedEmitter: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        private http: HttpClient,
        private globalConstants: GlobalConstants,
        private customerListService: CustomerListService,
        private deviceListService: DeviceListService,
        private networkListService: NetworkListService,
        private alert: AlertService,
        private encryptedLocalStorageService: EncryptedLocalStorageService,
        private store: Store<CosgridAppStore>,
        private router: Router,
    ) { }

    updateSelectedTenant(tenant) {
        const id = tenant.id;
        const name = tenant.tenantname;
        this.customerListService.selectedTenantId = id;
        this.customerListService.selectedTenantName = name;
        // TODO - remove this line
        // this.localStorage.setItem('tenant', JSON.stringify(tenant));
        this.encryptedLocalStorageService.setItem('tenant', JSON.stringify(tenant));
        this.store.dispatch(new SET_SELECTED_TENANT(tenant));

        //  this.http.post(this.globalConstants.DEVICE_LIST, { "tenant_id": id }).subscribe((data: any) => {
        //   const r = data;

        //   if (r.length > 0) {
        this.customerListService.dispatchSelectedCusomtersToStore(tenant);
        // this.deviceListService.dispatchAllDevicesToStore(r);
        // this.deviceListService.deviceList = r;
        this.deviceListService.dispatchSelectedDeviceToStore({}); // empty the selection
        this.networkListService.dispatchSelectedNetworkToStore(allNetworks); // empty the networks

        this.router.navigateByUrl('/loading').then(() => {
            this.router.navigateByUrl('/dashboard');
        });
        //   } else {
        //     this.deviceListService.dispatchAllDevicesToStore(r);
        //     this.alert.snack('There are no devices in the tenant');
        //   }
        // });
    }

    fetchDeviceList(): void {
        this.http.get(this.globalConstants.DEVICE_LIST).subscribe((data: any) => {
            const r = data;
            const list: Device[] = [];
            r.forEach((dev: any) => {
                const d: Device = {
                    name: dev.name,
                    id: dev.id,
                };
                list.push(d);
            });
            this.DeviceList.next(list);
        });
    }

    getNetworkIpsec(currentTenantId, networkId) {
        return this.http
            .get(
                this.globalConstants.status_tenant +
                'network/' +
                networkId +
                this.globalConstants.STATUS_NETWORK_MONITOR,
            )
            .pipe(
                map((data: any) => {
                    const r = data;
                    return r;
                }),
            );
    }

    getTenantMonitor(currentTenantId) {
        return this.http
            .post(this.globalConstants.STATUS_TENANT_MONITOR, {
                tenant_id: currentTenantId,
            })
            .pipe(
                map((data: any) => {
                    const r = data;
                    return r;
                }),
            );
    }

    fetchNetworkList(tid) {
        const params = new HttpParams().set('tid', tid);
        return this.http.get(this.globalConstants.TENANT_NETWORKLIST + '?' + params);
    }

    getDeviceList(): any {
        if (_.isEmpty(this.DeviceList)) {
            this.fetchDeviceList();
        }
        return this.DeviceList;
    }

    updateDevice(dev: Device): void {
        this.selectedDevice.next(dev.id);
    }

    emitOpenMenu() {
        this.openMenuEvent.emit();
    }

}
