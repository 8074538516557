import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyToTitle'
})
export class KeyToTitlePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return value.split('_').map((word) => word.slice(0, 1).toUpperCase() + word.slice(1)).join(" ")
  }

}
