<label *ngIf="name" [class.required]="isRequired"> {{name}}
  <!-- <Tooltip
  [description]="description"
  [format]="format"
  [note]="note"
  [preFill]="'subnet'"></Tooltip> -->
</label>
<div style="width: 130px;">
  <input autcomplete="off" placeholder="&nbsp;&nbsp;{{ placeholder }}"
    class="{{'form-control form-control-sm '+className}}"
    [ngClass]="{'is-invalid':data.invalid && (data.dirty || data.touched)}" type="text" [formControl]="data">
  <small class="label" *ngIf="description">{{description}}</small>
  <small *ngIf="data.invalid && (data.dirty || data.touched  )">

    <mat-error>
      <ul>
        <li *ngIf="data['errors']['required']">Field is required. </li>
      </ul>
    </mat-error>
  </small>
  <small *ngIf="data.invalid && (data.dirty || data.touched  )">
    <mat-error>
      <ul>
        <li *ngIf="data['errors']['ippattern']">Format 'x.x.x.x',where x is in the range 0-255</li>
        <li *ngIf="data['errors']['subpattern']">Format 'x.x.x.x/y',where x is in the range 0-255 <br>
          and y in the range 1-32
        </li>

        <li *ngIf="data['errors']['incorrectPort']">Port should be within 1-65535</li>
        <li *ngIf="data['errors']['incorrectRange']">Port range be seperated with ':'</li>
        <li *ngIf="data['errors']['incorrectRange']">Port range should be in ascending </li>

        <li *ngIf="data['errors']['incorrectUrl']">Enter correct URL</li>

        <li *ngIf="data['errors']['incorrectEmail']">Enter valid Email</li>
      </ul>
    </mat-error>
  </small>
</div>