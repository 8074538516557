<div class="headers">
    <h5 *ngIf="!data && !plainHeader; else edit" class="modal-title text-center" style="color: #fff;">Add a {{ name }}
    </h5>
    <ng-template #edit>
        <h5 *ngIf="data && !plainHeader" class="modal-title text-center" style="color: #fff;">Edit {{ name }}</h5>
    </ng-template>
    <h5 *ngIf=" !data &&plainHeader" class="modal-title text-center" style="color: #fff;">{{ name }}</h5>
    <button type="button" class="close" style="margin-top: -25px; color: white;margin-right: 10px;" data-dismiss="modal"
        mat-dialog-close aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>