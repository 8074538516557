import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'cosgrid-free-trail-booking-demo',
  templateUrl: './free-trail-booking-demo.component.html',
  styleUrls: ['./free-trail-booking-demo.component.scss']
})
export class FreeTrailBookingDemoComponent implements OnInit {
  needSkip: boolean = true
  clicked: boolean = false
  clickedIndices: Set<number> = new Set();
  youtubeUrl: SafeResourceUrl;
  simpleDialog: boolean = true

  links = [
    {
      name: 'Endpoint Client Application',
      link: 'https://www.youtube.com/embed/Bn_e5KRaeiM?si=5rcL6nXSv3au3ivJ'
    },
    {
      name: 'Users and group creation',
      link: 'https://www.youtube.com/embed/Bn_e5KRaeiM?si=5rcL6nXSv3au3ivJ'
    },
    {
      name: 'Endpoint profile creation',
      link: 'https://www.youtube.com/embed/Bn_e5KRaeiM?si=5rcL6nXSv3au3ivJ'
    },
    {
      name: 'Security groups creations',
      link: 'https://www.youtube.com/embed/Bn_e5KRaeiM?si=5rcL6nXSv3au3ivJ'
    },
    {
      name: 'Data protection policy',
      link: 'https://www.youtube.com/embed/Bn_e5KRaeiM?si=5rcL6nXSv3au3ivJ'
    },
    {
      name: 'Web Filering',
      link: 'https://www.youtube.com/embed/Bn_e5KRaeiM?si=5rcL6nXSv3au3ivJ'
    },
  ]
  constructor(
    private dialogRef: MatDialogRef<FreeTrailBookingDemoComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private sanitizer: DomSanitizer
  ) {
    this.needSkip = data.skipNeeded
    this.simpleDialog = data.simpleDialog
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close('closed')
  }

  scheduleCall() {
    window.open('https://cosgrid.com/company/contacts')
  }

  goToSignup() {
    this.dialogRef.close('signup')
  }

  openYoutube(url, index) {
    this.clicked = true
    if (!this.clickedIndices.has(index)) {
      this.clickedIndices.add(index);
    }
    this.youtubeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

}
