<ng-container *ngIf="loading;else elseTemplate">
    <img src="assets/loading-gif.gif" alt="loading" width="40px" style="z-index: 105;" />
</ng-container>


<ng-template #elseTemplate>

    <cosgrid-configure-modal-header name="Follow Ups" [plainHeader]="true"></cosgrid-configure-modal-header>
    <div class="p-3" style="max-height: 30rem;width: 50rem;overflow-y: auto;position: relative;">
        <!-- <div class="d-flex align-items-center justify-content-end mb-2"
            style="position: absolute;top: 1rem; right: 1rem;">
            <p class="fw-bold mr-2">Ticket Raised on: </p>
            <p>{{followups.created | timeformat:'DD/MM/yy'}}</p>
        </div>
        <div class="d-flex align-items-center justify-content-end">
            <p class="fw-bold mr-2">Submitted By: </p>
            <p>{{followups.submitter_email}}</p>
        </div> -->
        <p class="h5 mb-2"><span>Ticket#</span><span>{{followups.id}}</span> - <span>{{followups.title}}</span> -
            <span class="p-1 rounded" [ngStyle]="{'background-color': followups.status_display.toLowerCase() === 'open'
            ? '#34b000' :
            followups.status_display.toLowerCase() === 'closed'
              ? '#ff560b' :
              followups.status_display.toLowerCase() == 'resolved'
                ? '#0078be' :
                followups.status_display.toLowerCase() == 'duplicate'
                  ? '#6801af' : '#6801af'}" style="color: #fff;">{{followups.status_display}}</span>
        </p>
        <p class="mt-4 mb-2 h5">Description</p>
        <p class="border rounded p-1" style="height: 5rem; overflow-y: auto;">{{followups.description}}</p>
        <!-- <mat-stepper orientation="vertical" #stepper>
            <mat-step *ngFor="let item of followups.followups">
                <ng-template matStepLabel>{{item.title}}<i class="fa fa-angle-down ml-2"
                        style="font-size:20px;"></i></ng-template>

                <div class="d-flex align-items-center">
                    <p clas="mr-3">Comments</p>
                    <p>{{item.comment}}</p>
                </div>
                <div class="d-flex align-items-center">
                    <p clas="mr-3">Status</p>
                    <p class="p-1 rounded" [ngStyle]="{'background-color': followups.status_display.toLowerCase() === 'open'
                    ? '#34b000' :
                    followups.status_display.toLowerCase() === 'closed'
                      ? '#ff560b' :
                      followups.status_display.toLowerCase() == 'resolved'
                        ? 'green' :
                        followups.status_display.toLowerCase() == 'duplicate'
                          ? 'red' : 'orange'}" style="color: #fff;">{{item.status_display}}</p>
                </div>
                <p>{{item.status_display}}</p>
            </mat-step>
        </mat-stepper> -->
        <!-- <table>
            <tr>
                <td>Title</td>
                <td>{{followups.title}}</td>
            </tr>
            <tr>
                <td>Description</td>
                <td>{{followups.description}}</td>
            </tr>
            <tr>
                <td>Resolution</td>
                <td>{{followups.resolution}}</td>
            </tr>
            <tr>
                <td>Status</td>
                <td>{{followups.status_display}}</td>
            </tr>
            <tr>
                <td>Priority</td>
                <td>{{followups.priority_display}}</td>
            </tr>
        </table> -->
        <p class="mt-4 h5" style="margin-bottom: -20px;">Comments</p>
        <cosgrid-table [needSerialNumbers]="true" [rows]="followups.followups" [rowProps]="rowProps"
            [tableProps]="tableProps" [isConfig]="false"></cosgrid-table>
    </div>
</ng-template>