import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MicroZAccessComponent } from './modules/auth/components/micro-zaccess/micro-zaccess.component';
import { SocialSignUpComponent } from 'app/modules/auth/components/social-sign-up/social-sign-up.component';
import {
    GoogleLoginProvider,
    SocialAuthServiceConfig,
    SocialLoginModule,
    GoogleInitOptions,
} from '@abacritt/angularx-social-login';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppComponent } from './app.component';
// Core Imports
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

//date pipe and time



//Google map integration
import { AgmCoreModule } from '@agm/core';
//import { Ng4GeoautocompleteModule } from 'ng4-geoautocomplete';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

import { SharedCommonModule } from './shared/shared.module';

import { appRoutes } from './app.routes';
// Dialogs used for adding new items across application

//firebase for liveChatSupport and push notifications
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
// Providers
import { reducers } from './store/reducer.factory';

import { HotkeyModule } from 'angular2-hotkeys';
import { GoogleChartsModule } from 'angular-google-charts';

import { JoyrideModule } from 'ngx-joyride';
import { environment } from 'environments/environment.prod';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { CoreModule } from 'app/core/core.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { DashboardModule } from 'app/modules/dashboard/dashboard.module';
import { PreloadingStrategyService } from 'app/core/services/preloading-strategy.service';
import { GlobalErrorHandler } from 'app/core/services/global-error-handler';
import { RUMComponent } from './modules/rum/rum.component';
import { MsalGuard, MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { NgOptimizedImage, registerLocaleData } from '@angular/common';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ClipboardModule } from '@angular/cdk/clipboard';
import es from '@angular/common/locales/ebu';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
registerLocaleData(es);

export const MY_NATIVE_FORMATS = {
    fullPickerInput: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    },
    datePickerInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
    timePickerInput: { hour: 'numeric', minute: 'numeric' },
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
};

const oktaAuth = new OktaAuth({
    issuer: 'https://dev-25968173.okta.com/oauth2/default',
    clientId: '0oa8eeirmutEZMgse5d7',
    redirectUri: window.location.origin + '/login/callback',
});

const googleLoginOptions: GoogleInitOptions = {
    oneTapEnabled: false,
};

@NgModule({
    declarations: [AppComponent, SocialSignUpComponent, MicroZAccessComponent],
    imports: [
        // angular app modules core
        BrowserAnimationsModule,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        //firebase modules
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        //redux
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({
            maxAge: 20,
        }),
        TourMatMenuModule.forRoot(),
        // pagination pipe
        NgxPaginationModule,
        //FOR ONBOARDING
        JoyrideModule.forRoot(),
        // route definitions
        RouterModule.forRoot(appRoutes, {
            preloadingStrategy: PreloadingStrategyService,
            relativeLinkResolution: 'legacy',
        }),
        //third party modules for root
        AgmCoreModule.forRoot({
            apiKey: environment.agmCoreKey,
            libraries: ['places'],
        }),
        NgxSkeletonLoaderModule,
        //Ng4GeoautocompleteModule.forRoot(),
        GoogleChartsModule.forRoot(),
        HotkeyModule.forRoot(),
        LoggerModule.forRoot({
            serverLoggingUrl: 'https://cosgrid.net/logger/logs',
            level: NgxLoggerLevel.DEBUG,
            serverLogLevel: NgxLoggerLevel.TRACE,
        }),
        // LoggerModule.forRoot({ serverLoggingUrl: 'https://rum.cosgrid.net/logs', level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.TRACE }),
        //SHARED MODULES
        SharedCommonModule,
        //core module
        CoreModule,
        // eagerly loaded module

        DashboardModule,
        GooglePlaceModule,
        SocialLoginModule,
        OktaAuthModule,
        NgOptimizedImage,
        LazyLoadImageModule,
        ClipboardModule,

        MsalModule.forRoot(
            new PublicClientApplication({
                auth: {
                    clientId: '169db31b-fa5a-4d8-b417-a5a55dcb4459',
                    redirectUri: 'http://localhost:3200/auth/login',
                    authority: 'https://login.microsoftonline.com/common',
                },
            }),
            {
                interactionType: InteractionType.Redirect,
                authRequest: {
                    scopes: ['user.read'],
                },
            },
            {
                interactionType: InteractionType.Redirect,
                protectedResourceMap: new Map([['https://graph.microsoft.com/v1.0/me', ['user.Read']]]),
            },
        ),
    ],
    bootstrap: [AppComponent],
    providers: [
        MatDatepickerModule,
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
            provide: 'SocialAuthServiceConfig',

            useValue: {
                autoLogin: false,

                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(environment.googleClientId, googleLoginOptions),
                    },
                ],
                onError: (err) => {
                    console.error(err);
                },
            } as SocialAuthServiceConfig,
        },
        { provide: OKTA_CONFIG, useValue: { oktaAuth } },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        MsalGuard,
    ],
})

//TODO: export sdwan service , remove explicit declaration of sdwan service
export class AppModule {
    constructor() { }
}
