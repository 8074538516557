<div class="header">
    <h3 style="font-weight: bold;color: #fff;font-size: 17px;margin: 0; margin-top: 5px; margin-left: 5px;">
        {{simpleDialog ? 'Explore Simplified Zero Trust Access with Web Filtering' : 'Explore'}}
    </h3>
</div>
<div class="modal-body d-flex align-items-center flex-column" *ngIf="simpleDialog">
    <img src="assets/icons/COSGrid symbol HD Black .png" alt="Logo" width="60px" height="60px" class="my-2">

    <p>Let's discuss your specific use case and how can we assists your efforts</p>
    <div class="d-flex align-items-center justify-content-around mt-4">
        <button (click)="close()" *ngIf="needSkip" class="btn btn-primary" style="width: 130px;">
            Skip
        </button>
        <button (click)="scheduleCall()" class="btn btn-primary mx-2" style="width: 130px;">
            Schedule a Call
        </button>
        <button (click)="goToSignup()" class="btn btn-primary" style="width: 130px;">
            Sign Up
        </button>
    </div>
</div>

<div *ngIf="!simpleDialog" class=" modal-body d-flex align-items-center flex-column position-relative">
    <img src="assets/icons/COSGrid symbol HD Black .png" alt="Logo" width="60px" height="60px" class="my-2">
    <div id="iframe-container" *ngIf="youtubeUrl && clicked">
        <button class="close-btn" id="close-iframe" (click)="clicked = false">&times;</button>
        <iframe width="560" height="315" [src]="youtubeUrl" title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div>

    <div class="row">
        <div class="col-6" *ngFor="let item of links;let i = index">
            <a [class.strikeout]="clickedIndices.has(i)" (click)="openYoutube(item.link, i)">{{item.name}}</a>
            <p>&gt;</p>
        </div>
    </div>
    <div class="d-flex align-items-center justify-content-around mt-4">
        <button (click)="close()" class="btn btn-primary" style="width: 130px;">
            Skip
        </button>
        <button (click)="scheduleCall()" class="btn btn-primary mx-2" style="width: 130px;">
            Schedule a Call
        </button>
        <button (click)="goToSignup()" class="btn btn-primary" style="width: 130px;">
            Sign Up
        </button>
    </div>
</div>