<div class="wrapper">
  <div class="m-3">
    <h2 class="mb-3">Raise a Ticket </h2>
    <div style="width: 60%;">
      <form [formGroup]="ticketRaiseForm">
        <div class="form-row">
          <div class="form-group col-5">
            <config-select formControlName="product" name="Product" [options]="['Reflex SD-WAN', 'MicroZAccess']"
              placeholder="Choose your product" description="Choose the issue product"
              [className]="'col-11 '"></config-select>
          </div>
          <div class="form-group col-5">
            <config-select name="Issue In" formControlName="device"
              [options]="ticketRaiseForm.value.product == 'Reflex SD-WAN' ? devices.sdwan : devices.mza" viewKey="name"
              valueKey="name" placeholder="Select issue device" description="Choose the device for raise an issue"
              [className]="'col-11 '" [multiple]="true"></config-select>
          </div>
        </div>
        <div class="form-row">

          <div class="form-group col-5">
            <config-select name="Priority" formControlName="priority"
              [options]="[{value: 1, view: 'Urgent (P1)'}, {value: 2, view: 'High (P2)'}, {value: 3, view: 'Medium (P3)'}, {value: 4, view: 'Low (P4)'}, {value: 5, view: 'Very Low (P5)'}]"
              placeholder="How Important" viewKey="view" valueKey="value" description="How important this issue"
              [className]="'col-11'"></config-select>
          </div>
          <div class="form-group col-5">
            <config-input name="Issue Name" formControlName="title" placeholder="Issue Name"
              desctiption="Enter the name of the issue" [className]="'col-11'"></config-input>
          </div>
        </div>
        <!-- <div class="d-flex mb-4">
          <div class="form-group col-6">
            <config-input name="Issue Name" formControlName="issue_sub" placeholder="Issue Name"
              desctiption="Enter the name of the issue" [className]="'col-11'"></config-input>
          </div>
          <div class="form-group col-6 ml-3 mt-2">
            <label>Mode of Contact</label><br>
            <mat-radio-group aria-label="Select an option" formControlName="mode_contact">
              <mat-radio-button value="Email">Email</mat-radio-button>
              <mat-radio-button value="Phone">Phone</mat-radio-button>
            </mat-radio-group>
          </div>
        </div> -->
        <div class="form-row">
          <div class="form-group col-10">
            <config-input name="Description" type="string" formControlName="description"
              placeholder="Detailed Description of the issue"
              desctiption="Enter the detail description of the issue"></config-input>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <button (click)="submitForm()" class="btn btn-primary">
            Submit <i *ngIf="loading" class="fa fa-spinner fa-pulse fa-fw"></i>
          </button>
        </div>
      </form>
    </div>

  </div>
</div>